.E_10 {
  background-image: url("./svg/10C.svg");
}
.D_10 {
  background-image: url("./svg/10D.svg");
}
.H_10 {
  background-image: url("./svg/10H.svg");
}
.S_10 {
  background-image: url("./svg/10S.svg");
}
.E_09 {
  background-image: url("./svg/9C.svg");
}
.D_09 {
  background-image: url("./svg/9D.svg");
}
.H_09 {
  background-image: url("./svg/9H.svg");
}
.S_09 {
  background-image: url("./svg/9S.svg");
}
.E_14A {
  background-image: url("./svg/AC.svg");
}
.D_14A {
  background-image: url("./svg/AD.svg");
}
.H_14A {
  background-image: url("./svg/AH.svg");
}
.S_14A {
  background-image: url("./svg/AS.svg");
}
.E_999J {
  background-image: url("./svg/JC.svg");
}
.D_999J {
  background-image: url("./svg/JD.svg");
}
.H_999J {
  background-image: url("./svg/JH.svg");
}
.S_999J {
  background-image: url("./svg/JS.svg");
}
.E_13K {
  background-image: url("./svg/KC.svg");
}
.D_13K {
  background-image: url("./svg/KD.svg");
}
.H_13K {
  background-image: url("./svg/KH.svg");
}
.S_13K {
  background-image: url("./svg/KS.svg");
}
.E_12Q {
  background-image: url("./svg/QC.svg");
}
.D_12Q {
  background-image: url("./svg/QD.svg");
}
.H_12Q {
  background-image: url("./svg/QH.svg");
}
.S_12Q {
  background-image: url("./svg/QS.svg");
}
