.currentUser {
  justify-content: flex-end;
}

.author {
  font-size: 8pt;
  margin-right: 0.5em;
  text-align: right;
}

.outer {
  margin: 5px;
  font-family: Roboto, sans-serif;
  margin-top: 1em;
}

.container {
  display: flex;
}

.messages {
  flex-shrink: 1;
  margin: 0 0.5em 0.5em 1em;
  padding: 0.5em;
  font-size: 11pt;
  background-color: #fffff4;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 0.4em;
}

.messages:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0%;
  top: 0%;
  width: 0;
  height: 0;
  border: 1em solid transparent;
  margin-left: -0.5em;
  margin-bottom: -1em;

  border-right-color: #fffff4;
  border-left: 0;
  border-top: 0;
}

.currentUser .messages {
  background-color: #eee;
}

.currentUser .messages:after {
  left: 100%;
  border: 1em solid transparent;
  border-left-color: #eee;
  border-top: 0;
  border-right: 0;
}

.pending {
  font-style: oblique;
  color: #ccc;
}
