.C_10 {
  background-image: url("./svg/10C.svg");
}
.D_10 {
  background-image: url("./svg/10D.svg");
}
.H_10 {
  background-image: url("./svg/10H.svg");
}
.S_10 {
  background-image: url("./svg/10S.svg");
}
.D_02 {
  background-image: url("./svg/2D.svg");
}
.H_02 {
  background-image: url("./svg/2H.svg");
}
.S_02 {
  background-image: url("./svg/2S.svg");
}
.C_03 {
  background-image: url("./svg/3C.svg");
}
.D_03 {
  background-image: url("./svg/3D.svg");
}
.H_03 {
  background-image: url("./svg/3H.svg");
}
.S_03 {
  background-image: url("./svg/3S.svg");
}
.C_04 {
  background-image: url("./svg/4C.svg");
}
.D_04 {
  background-image: url("./svg/4D.svg");
}
.H_04 {
  background-image: url("./svg/4H.svg");
}
.S_04 {
  background-image: url("./svg/4S.svg");
}
.C_05 {
  background-image: url("./svg/5C.svg");
}
.D_05 {
  background-image: url("./svg/5D.svg");
}
.H_05 {
  background-image: url("./svg/5H.svg");
}
.S_05 {
  background-image: url("./svg/5S.svg");
}
.C_06 {
  background-image: url("./svg/6C.svg");
}
.D_06 {
  background-image: url("./svg/6D.svg");
}
.H_06 {
  background-image: url("./svg/6H.svg");
}
.S_06 {
  background-image: url("./svg/6S.svg");
}
.C_07 {
  background-image: url("./svg/7C.svg");
}
.D_07 {
  background-image: url("./svg/7D.svg");
}
.H_07 {
  background-image: url("./svg/7H.svg");
}
.S_07 {
  background-image: url("./svg/7S.svg");
}
.C_08 {
  background-image: url("./svg/8C.svg");
}
.D_08 {
  background-image: url("./svg/8D.svg");
}
.H_08 {
  background-image: url("./svg/8H.svg");
}
.S_08 {
  background-image: url("./svg/8S.svg");
}
.C_09 {
  background-image: url("./svg/9C.svg");
}
.D_09 {
  background-image: url("./svg/9D.svg");
}
.H_09 {
  background-image: url("./svg/9H.svg");
}
.S_09 {
  background-image: url("./svg/9S.svg");
}
.C_14A {
  background-image: url("./svg/AC.svg");
}
.D_14A {
  background-image: url("./svg/AD.svg");
}
.H_14A {
  background-image: url("./svg/AH.svg");
}
.S_14A {
  background-image: url("./svg/AS.svg");
}
.C_11J {
  background-image: url("./svg/JC.svg");
}
.D_11J {
  background-image: url("./svg/JD.svg");
}
.H_11J {
  background-image: url("./svg/JH.svg");
}
.S_11J {
  background-image: url("./svg/JS.svg");
}
.C_13K {
  background-image: url("./svg/KC.svg");
}
.D_13K {
  background-image: url("./svg/KD.svg");
}
.H_13K {
  background-image: url("./svg/KH.svg");
}
.S_13K {
  background-image: url("./svg/KS.svg");
}
.C_12Q {
  background-image: url("./svg/QC.svg");
}
.D_12Q {
  background-image: url("./svg/QD.svg");
}
.H_12Q {
  background-image: url("./svg/QH.svg");
}
.S_12Q {
  background-image: url("./svg/QS.svg");
}
