
.man1svg {
	background-image: url('svg/1man.svg');
}
.pin1svg {
	background-image: url('svg/1pin.svg');
}
.sou1svg {
	background-image: url('svg/1sou.svg');
}
.man2svg {
	background-image: url('svg/2man.svg');
}
.pin2svg {
	background-image: url('svg/2pin.svg');
}
.sou2svg {
	background-image: url('svg/2sou.svg');
}
.man3svg {
	background-image: url('svg/3man.svg');
}
.pin3svg {
	background-image: url('svg/3pin.svg');
}
.sou3svg {
	background-image: url('svg/3sou.svg');
}
.man4svg {
	background-image: url('svg/4man.svg');
}
.pin4svg {
	background-image: url('svg/4pin.svg');
}
.sou4svg {
	background-image: url('svg/4sou.svg');
}
.man5svg {
	background-image: url('svg/5man.svg');
}
.pin5svg {
	background-image: url('svg/5pin.svg');
}
.sou5svg {
	background-image: url('svg/5sou.svg');
}
.man6svg {
	background-image: url('svg/6man.svg');
}
.pin6svg {
	background-image: url('svg/6pin.svg');
}
.sou6svg {
	background-image: url('svg/6sou.svg');
}
.man7svg {
	background-image: url('svg/7man.svg');
}
.pin7svg {
	background-image: url('svg/7pin.svg');
}
.sou7svg {
	background-image: url('svg/7sou.svg');
}
.man8svg {
	background-image: url('svg/8man.svg');
}
.pin8svg {
	background-image: url('svg/8pin.svg');
}
.sou8svg {
	background-image: url('svg/8sou.svg');
}
.man9svg {
	background-image: url('svg/9man.svg');
}
.pin9svg {
	background-image: url('svg/9pin.svg');
}
.sou9svg {
	background-image: url('svg/9sou.svg');
}
