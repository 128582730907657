.glossytile {
  display: inline-block;
  position: relative;

  color: white;
  background-color: ivory;
  padding: 0;
  border: none;
  border-radius: 3px;
  margin: 5px;
  background-size: cover;
  box-shadow: inset 0 -4% 16% hsla(0, 0%, 0%, 0.4),
    /* top light */ inset 0 4% 16% hsla(0, 0%, 100%, 0.4),
    /* bottom shadow */ -6% 6% 4% hsla(0, 0%, 0%, 0.15),
    /* drop shadow 1 */ 4% 14% 8% hsla(0, 0%, 0%, 0.2); /* drop shadow 2 */
  cursor: pointer;
  transition: transform 0.1s, box-shadow 0.1s;
}

.glossytile:before {
  content: "";
  display: block;
  position: absolute;
  left: 15%;
  right: 15%;
  top: 5px;
  height: 25%;
  border-radius: 30%;
  --background: linear-gradient(hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0));
}

.glossytile:hover {
  --transform: scale(1.05);
  --box-shadow: inset 0 -5px 20px hsla(0, 0%, 0%, 0.4),
    /* top light */ inset 0 5px 20px hsla(0, 0%, 100%, 0.4),
    /* bottom shadow */ /* multiple light sources yall */ -12px 12px 5px hsla(0, 0%, 0%, 0.15),
    /* drop shadow 1 */ 10px 25px 10px hsla(0, 0%, 0%, 0.2); /* drop shadow 2 */
}
.green {
  background-color: #004e00;
  color: ivory;
  padding: 1em;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}

.skyblue {
  background-color: #4055df;
  color: ivory;
  padding: 1em;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}

.glossytile div {
  height: 129px;
  width: 75px;
}

.divider {
  display: block;
  width: 100%;
  height: 10px;
}

.melds {
  border: 1px solid yellow;
  margin: 4px;
  padding: 1em;
}

.discards {
  margin: 4px;
  padding: 1em;
  display: inline-block;
}

.board {
  display: inline-block;
  height: min(50vh, 90vw);
  width: min(50vh, 90vw);
  background-image: url('./img/board.jpg');
  background-size: cover;
}
.center {
  text-align: center;
}

.discards div {
  display: inline-block;
}

.melds div {
  display: inline-block;
}

.cardback {
  background-image: url("./img/CardBack.jpg");
}

.rack {
  border: 1px solid red;
}

.hand {
  border: 1px solid yellow;
  display: inline-block;
  margin-top: -420px;
}