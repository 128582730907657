html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #f9fdff;
  font-family: "Helvetica Neue", arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#root, #app {
  height: 100%;
}
