.Albania_1 {
  background-image: url("./img/Albania.jpg");
}

.Atlantic_1 {
  background-image: url("./img/Atlantic.jpg");
}

.Atlantic_2 {
  background-image: url("./img/Atlantic.jpg");
}

.Atlantic_3 {
  background-image: url("./img/Atlantic.jpg");
}

.Atlantic_4 {
  background-image: url("./img/Atlantic.jpg");
}

.Austria_1 {
  background-image: url("./img/Austria.jpg");
}

.Baltic_1 {
  background-image: url("./img/Baltic.jpg");
}

.Baltic_2 {
  background-image: url("./img/Baltic.jpg");
}

.Belarus_1 {
  background-image: url("./img/Belarus.jpg");
}

.Belgium_1 {
  background-image: url("./img/Belgium.jpg");
}

.BlueAir_1 {
  background-image: url("./img/BlueAir.jpg");
}

.BlueAir_2 {
  background-image: url("./img/BlueAir.jpg");
}

.Bosnia_1 {
  background-image: url("./img/Bosnia.jpg");
}

.Bulgaria_1 {
  background-image: url("./img/Bulgaria.jpg");
}

.Croatia_1 {
  background-image: url("./img/Croatia.jpg");
}

.Czech_1 {
  background-image: url("./img/Czech.jpg");
}

.Denmark_2 {
  background-image: url("./img/Denmark.jpg");
}

.Denmark_1 {
  background-image: url("./img/Denmark.jpg");
}

.England_1 {
  background-image: url("./img/England.jpg");
}

.Estonia_1 {
  background-image: url("./img/Estonia.jpg");
}

.Finland_1 {
  background-image: url("./img/Finland.jpg");
}

.France_2 {
  background-image: url("./img/France.jpg");
}

.France_1 {
  background-image: url("./img/France.jpg");
}

.Germany_1 {
  background-image: url("./img/Germany.jpg");
}

.Germany_2 {
  background-image: url("./img/Germany.jpg");
}

.Greece_1 {
  background-image: url("./img/Greece.jpg");
}

.GreenAir_1 {
  background-image: url("./img/GreenAir.jpg");
}

.GreenAir_2 {
  background-image: url("./img/GreenAir.jpg");
}

.Hungary_1 {
  background-image: url("./img/Hungary.jpg");
}

.Iceland_1 {
  background-image: url("./img/Iceland.jpg");
}

.Ireland_1 {
  background-image: url("./img/Ireland.jpg");
}

.Italy_1 {
  background-image: url("./img/Italy.jpg");
}

.Latvia_1 {
  background-image: url("./img/Latvia.jpg");
}

.Lithuania_1 {
  background-image: url("./img/Lithuania.jpg");
}

.Luxembourg_1 {
  background-image: url("./img/Luxembourg.jpg");
}

.Macedonia_1 {
  background-image: url("./img/Macedonia.jpg");
}

.Mediterranean_1 {
  background-image: url("./img/Mediterranean.jpg");
}

.Mediterranean_2 {
  background-image: url("./img/Mediterranean.jpg");
}

.Mediterranean_3 {
  background-image: url("./img/Mediterranean.jpg");
}

.Moldova_1 {
  background-image: url("./img/Moldova.jpg");
}

.Montenegro_1 {
  background-image: url("./img/Montenegro.jpg");
}

.Netherlands_1 {
  background-image: url("./img/Netherlands.jpg");
}

.NorthernIreland_1 {
  background-image: url("./img/NorthernIreland.jpg");
}

.Norway_1 {
  background-image: url("./img/Norway.jpg");
}

.OrangeAir_1 {
  background-image: url("./img/OrangeAir.jpg");
}

.OrangeAir_2 {
  background-image: url("./img/OrangeAir.jpg");
}

.PinkAir_1 {
  background-image: url("./img/PinkAir.jpg");
}

.PinkAir_2 {
  background-image: url("./img/PinkAir.jpg");
}

.Poland_1 {
  background-image: url("./img/Poland.jpg");
}

.Portugal_1 {
  background-image: url("./img/Portugal.jpg");
}

.Romania_1 {
  background-image: url("./img/Romania.jpg");
}

.Russia_2 {
  background-image: url("./img/Russia.jpg");
}

.Russia_1 {
  background-image: url("./img/Russia.jpg");
}

.Scotland_1 {
  background-image: url("./img/Scotland.jpg");
}

.Serbia_1 {
  background-image: url("./img/Serbia.jpg");
}

.Slovakia_1 {
  background-image: url("./img/Slovakia.jpg");
}

.Slovenia_1 {
  background-image: url("./img/Slovenia.jpg");
}

.Spain_2 {
  background-image: url("./img/Spain.jpg");
}

.Spain_1 {
  background-image: url("./img/Spain.jpg");
}

.Sweden_2 {
  background-image: url("./img/Sweden.jpg");
}

.Sweden_1 {
  background-image: url("./img/Sweden.jpg");
}

.Switzerland_1 {
  background-image: url("./img/Switzerland.jpg");
}

.Turkey_1 {
  background-image: url("./img/Turkey.jpg");
}

.Ukraine_1 {
  background-image: url("./img/Ukraine.jpg");
}

.Wales_1 {
  background-image: url("./img/Wales.jpg");
}

.YellowAir_1 {
  background-image: url("./img/YellowAir.jpg");
}

.YellowAir_2 {
  background-image: url("./img/YellowAir.jpg");
}

