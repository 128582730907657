
.man1e {
	background-image: url('svg/1man.svg');
}
.pin1e {
	background-image: url('svg/1pin.svg');
}
.sou1e {
	background-image: url('svg/1sou.svg');
}
.man2e {
	background-image: url('svg/2man.svg');
}
.pin2e {
	background-image: url('svg/2pin.svg');
}
.sou2e {
	background-image: url('svg/2sou.svg');
}
.man3e {
	background-image: url('svg/3man.svg');
}
.pin3e {
	background-image: url('svg/3pin.svg');
}
.sou3e {
	background-image: url('svg/3sou.svg');
}
.man4e {
	background-image: url('svg/4man.svg');
}
.pin4e {
	background-image: url('svg/4pin.svg');
}
.sou4e {
	background-image: url('svg/4sou.svg');
}
.man5e {
	background-image: url('svg/5man.svg');
}
.pin5e {
	background-image: url('svg/5pin.svg');
}
.sou5e {
	background-image: url('svg/5sou.svg');
}
.man6e {
	background-image: url('svg/6man.svg');
}
.pin6e {
	background-image: url('svg/6pin.svg');
}
.sou6e {
	background-image: url('svg/6sou.svg');
}
.man7e {
	background-image: url('svg/7man.svg');
}
.pin7e {
	background-image: url('svg/7pin.svg');
}
.sou7e {
	background-image: url('svg/7sou.svg');
}
.man8e {
	background-image: url('svg/8man.svg');
}
.pin8e {
	background-image: url('svg/8pin.svg');
}
.sou8e {
	background-image: url('svg/8sou.svg');
}
.man9e {
	background-image: url('svg/9man.svg');
}
.pin9e {
	background-image: url('svg/9pin.svg');
}
.sou9e {
	background-image: url('svg/9sou.svg');
}
.man1e:before {
	color: red;
	content: "1";
	font-size: 10px;
}
.man2e:before {
	color: red;
	content: "2";
	font-size: 10px;
}
.man3e:before {
	color: red;
	content: "3";
	font-size: 10px;
}
.man4e:before {
	color: red;
	content: "4";
	font-size: 10px;
}
.man5e:before {
	color: red;
	content: "5";
	font-size: 10px;
}
.man6e:before {
	color: red;
	content: "6";
	font-size: 10px;
}
.man7e:before {
	color: red;
	content: "7";
	font-size: 10px;
}
.man8e:before {
	color: red;
	content: "8";
	font-size: 10px;
}
.man9e:before {
	color: red;
	content: "9";
	font-size: 10px;
}
